import Box from "@mui/material/Box";
import React from "react";

export default function TabPanel(props) {
    const { children, value, index, tabPanelName, tabName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${tabPanelName}-${index}`}
            aria-labelledby={`${tabName}-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: "24px 0 0 0" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}