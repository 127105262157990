import React from "react";
import {TextField} from "@mui/material";

export default function UpdatingTextfield(props) {
    let fieldValue = !props.multilineAsArray ? props.value || "" : (props.value || "").join("\n")
    let timeoutId = null

    function updateTextField(evt) {
        fieldValue = evt.target.value;

        if (timeoutId !== null ) {
            clearTimeout(timeoutId)
        }

        timeoutId = setTimeout( () => {
            props.valueUpdated(props.multilineAsArray ? fieldValue.split("\n") : fieldValue)
        }, 1000)
    }

    return (
        <TextField style={props.style} label={props.label} multiline variant="outlined" defaultValue={fieldValue} onChange={evt => updateTextField(evt)} />
    );
}