import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import PushPinIcon from "@mui/icons-material/PushPin";
import {Box} from "@mui/material";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function Options(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const optionChanged = (name, value) => {
        handleClose();
        setTimeout( () => {
            props.optionChanged(name, value);
        }, 300)
    }

    return (
        <div>
            <IconButton
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        onClick={handleClick}
            >
                <SettingsIcon />
            </IconButton>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => optionChanged("expandNewItems", !props.options.expandNewItems)} disableRipple>
                    {
                        props.options.expandNewItems ?
                            <Box>
                                <UnfoldLessIcon style={{position: "relative", top: "3px"}} />
                                Collapse new items on create
                            </Box>:
                            <Box>
                                <UnfoldMoreIcon style={{position: "relative", top: "3px"}} />
                                Expand new items on create
                            </Box>
                    }
                </MenuItem>
                <MenuItem onClick={() => optionChanged("pinNewItems", !props.options.pinNewItems)} disableRipple>
                    {
                        props.options.pinNewItems ?
                            <Box>
                                <PushPinOutlinedIcon style={{transform: "rotate(45deg)", position: "relative", top: "4px"}} />
                                Do not pin new items
                            </Box>:
                            <Box>
                                <PushPinIcon style={{color: "white", transform: "rotate(45deg)", position: "relative", top: "4px"}} />
                                Pin new items
                            </Box>
                    }
                </MenuItem>
                {/*<Divider sx={{ my: 0.5 }} />*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <ArchiveIcon />*/}
                {/*    Archive*/}
                {/*</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <MoreHorizIcon />*/}
                {/*    More*/}
                {/*</MenuItem>*/}
            </StyledMenu>
        </div>
    );
}