import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={()=>{props.cancelClicked()}}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {props.contents || ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{props.cancelClicked()}} autoFocus>Cancel</Button>
                    <Button onClick={()=>{props.okClicked()}}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}