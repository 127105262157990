import React from "react";
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const styles = {
    addButton: {
        borderColor: "white",
        color: "white",
        paddingLeft: '10px',
        marginRight: '5px'
    }
}
export default function Toolbar(props) {
    const [searchText, setSearchText] = useStateWithCallbackLazy("");

    const updateSearchText = (val, callback) => {
        setSearchText(val, callback);
    }

    return (
        <div style={{margin: '0'}}>
            <Paper
                sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '0'}}
            >
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {
                    searchText.length > 0 &&
                    <IconButton sx={{ p: '10px' }} aria-label="search" onClick={() => updateSearchText("", () => {props.searchClicked(null)})}>
                        <HighlightOffOutlinedIcon />
                    </IconButton>
                }
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={searchText}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search questions' }}
                    onChange={evt => updateSearchText(evt.target.value, ()=>{})}
                    onKeyUp={(e) => {
                        const ENTER = 13;

                        let key = e.key || e.keyCode
                        if (key === "Enter" || key === ENTER) {
                            props.searchClicked(searchText)
                        }
                    }}
                />
                <IconButton sx={{ p: '10px' }} aria-label="search" onClick={() => props.searchClicked(searchText)}>
                    <SearchIcon />
                </IconButton>
                { props.showAdd &&
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                }
                { props.showAdd &&
                    <Button endIcon={<AddBoxIcon />} style={{...styles.addButton}} onClick={() => props.addButtonHandler()} >
                    Add New
                    </Button>
                }
            </Paper>
        </div>
    )
}
