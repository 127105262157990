import React from "react";
import {Box, TextField} from "@mui/material";

export default function SingleAnswerQuestion(props) {
    const answers = [...props.answers]
    let timeoutId = null

    function updateTextField(evt, answerIndex) {
        switch (answerIndex) {
            case 0: answers[0].text = evt.target.value; break
            default: break
        }

        if (timeoutId !== null ) {
            clearTimeout(timeoutId)
        }

        timeoutId = setTimeout( () => {
            props.answersUpdated([...answers])
        }, 1000)
    }

    return (
        <Box>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Correct answer" color="success" variant="outlined" defaultValue={answers[0] && answers[0].text} onChange={evt => updateTextField(evt, 0)}/>
        </Box>
    );
}