class Backend {
    constructor(apiUrl, msal) {
        this._apiUrl = apiUrl
        this.msal = msal
    }

    getQuizzes(token) {
        return this.doGet(`${this._apiUrl}/quizzes`, token)
    }

    getQuestions(token) {
        return this.doGet(`${this._apiUrl}/questions`, token);
    }

    async addQuestion(token) {
        return await this.doPost(`${this._apiUrl}/questions`, token, "");
    }

    async updateQuestion(token, questionId, data) {
        return await this.doPatch(`${this._apiUrl}/questions/${questionId}`, token, data);
    }


    async removeQuestion(token, questionId) {
        return await this.doDelete(`${this._apiUrl}/questions/${questionId}`, token);
    }

    doGet(url, token) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        return fetch(url, options)
            .then(response => response.json());
    }

    doDelete(url, token) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "DELETE",
            headers: headers
        };

        return fetch(url, options)
            .then(() => {});
    }

    doPost(url, token, data) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        };

        return fetch(url, options)
            .then(response => response.json());
    }

    doPatch(url, token, data) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);
        headers.append("Content-type", "application/json");

        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(data)
        };

        return fetch(url, options)
            .then(response => response.json());
    }
}

let apiUrl = process.env.REACT_APP_API_END_POINT;
if (apiUrl === "null" || apiUrl === undefined) {
    apiUrl = null;
}

export default new Backend(apiUrl)
