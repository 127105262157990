// noinspection ES6CheckImport
import {Navigate, Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import React, {useEffect, useLayoutEffect} from 'react';
import Quiz from "./routes/quizzes/quiz";
import SelectCorrect from "./routes/games/select-correct";
import OrderList from "./routes/games/order-list";
import './App.scss';
import {createTheme, ThemeProvider} from "@mui/material";
import Questions from "./routes/questions/questions";
import MenuDrawer from "./menu-drawer";
import ResponsiveAppBar from "./AppBar";
import Backend from "./common/backend";
import Utils from "./common/utils";
import TemporaryDrawer from "./temporary-drawer";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "./authConfig";

function getQuizzesFromApi(token) {
    let promises = [
        Backend.getQuizzes(token),
    ];
    return Promise.all(promises)
}

function App() {
    const [quizzes, setQuizzes] = React.useState([]);
    const [sideMenuOpen, setSideMenuOpen] = React.useState(window.innerWidth > 768);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const {instance, accounts} = useMsal();

    useEffect(() => {
        instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).then(async (response) => {
            setQuizzes((await getQuizzesFromApi(response.accessToken))[0]);
        }).catch((e) => {
            console.log(e)
            const currentAccount = instance.getActiveAccount();
            instance.logoutRedirect({
                account: currentAccount
            }).then(()=>{});
        });

    }, [instance, accounts])

    useLayoutEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth <= 768)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const splits = location.pathname.split("/");
    const quizIdInUrl = splits[splits.length - 1];
    //const quizzesOpen = location.pathname.startsWith("/quizzes/") || location.pathname === "/" || location.pathname === "/quizzes"
    const currentQuiz = Utils.findById(quizzes, quizIdInUrl);

    //function addQuiz(name) {
    //    setQuizzes([...quizzes, {id: name.replace(/\s/g, ""), name: name, groups: []}])
    //}

    function removeQuiz() {
        setQuizzes([...Utils.excludeById(quizzes, currentQuiz.id)]);
        navigate('/quizzes', {replace: true});
    }

    function removeGroup(quizId, groupId) {
        let newQuizzes = [...quizzes];
        let targetQuiz = Utils.findById(newQuizzes, quizId);
        targetQuiz.groups = [...Utils.excludeById(targetQuiz.groups, groupId)];
        setQuizzes([...newQuizzes]);
    }

    function updateGroups(quizId, groups) {
        let updatedQuizzes = [...quizzes];
        let updatedQuiz = Utils.findById(updatedQuizzes, quizId);
        updatedQuiz.groups = groups;
        setQuizzes([...updatedQuizzes]);
    }

    function addGroup(quizId, name) {
        let newQuizzes = [...quizzes]
        let newQuiz = Utils.findById(newQuizzes, currentQuiz.id)
        newQuiz.groups.push({
            questions: [],
            name: name,
            id: newQuiz.groups.length + 1
        });
        setQuizzes(newQuizzes);
    }

    function addQuestion(quizId, groupId) {
        // noinspection DuplicatedCode
        let newQuizzes = [...quizzes];
        let newQuiz = Utils.findById(newQuizzes, currentQuiz.id);
        let newGroup = Utils.findById(newQuiz.groups, groupId);

        newGroup.questions.push({
            answers: [{ "text": "", "index": 0, "correct": true}, {"text": "", "index": 1}, {"text": "", "index": 2}, {"text": "", "index": 3}],
            question: "",
            type: "select-correct",
            reference: "",
            facts: "",
            id: newGroup.questions.length + 1
        });
        setQuizzes(newQuizzes);
    }

    function updateQuestion(quizId, groupId, questionId, updatedData) {
        let updatedQuizzes = [...quizzes];
        let updatedQuiz = Utils.findById(updatedQuizzes, currentQuiz.id);
        let updatedGroup = Utils.findById(updatedQuiz.groups, groupId);
        let updatedQuestion = Utils.findById(updatedGroup.questions, questionId);

        //console.log(questionId, updatedData)

        updatedQuestion.question = updatedData.question;
        updatedQuestion.answers[0]["text"] = updatedData.answers[0];
        updatedQuestion.answers[1]["text"] = updatedData.answers[1];
        updatedQuestion.answers[2]["text"] = updatedData.answers[2];
        updatedQuestion.answers[3]["text"] = updatedData.answers[3];

        setQuizzes(updatedQuizzes);
    }

    function toggleSideMenu() {
        setSideMenuOpen(!sideMenuOpen);
    }
    function _setSideMenuOpen(val) {
        setSideMenuOpen(val);
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <ResponsiveAppBar sideMenuOpen={sideMenuOpen} toggleSideMenu={toggleSideMenu}/>
            <div style={styles.wrapper}>
                {
                    !isMobile ?
                        <MenuDrawer sideMenuOpen={sideMenuOpen} setSideMenuOpen={_setSideMenuOpen}/> :
                        <TemporaryDrawer setSideMenuOpen={_setSideMenuOpen} sideMenuOpen={sideMenuOpen} />
                }
                <div style={{...styles.main}}>
                    <Routes>
                        {quizzes.length > 0 ? <Route exact path="/" element={<Navigate replace to={"/questions"} />}/> : <Route exact path="/" element={<Empty />}/>}
                        <Route exact path="/questions" element={<Questions isMobile={isMobile} />}/>
                        {quizzes.length > 0 ? <Route exact path="/quizzes" element={<Navigate replace to={"/quizzes/" + quizzes[0].id} />}/> : <Route exact path="/quizzes" element={<Empty />}/>}
                        {quizzes.length > 0 ?
                            <Route path="/quizzes/*"
                                   element={<Quiz quiz={currentQuiz}
                                                  addGroupHandler={addGroup}
                                                  addQuestionHandler={addQuestion}
                                                  updateQuestionHandler={updateQuestion}
                                                  removeQuizHandler={removeQuiz}
                                                  removeGroupHandler={removeGroup}
                                                  updateGroupsHandler={updateGroups}
                                   />}
                            /> : <Route path="/quizzes/*" element={<Empty />}/>}
                        <Route path="games/select-correct" element={<SelectCorrect />} />
                        <Route path="/games/order-list" element={<OrderList/>} />
                    </Routes>
                </div>
            </div>
        </ThemeProvider>
    );
}

function Empty() {
    return ( <div /> );
}

const styles = {
    wrapper: {
        display: "flex",
        position: "relative",
        marginTop: '70px'
    },
    main: {
        padding: 10,
        color: "white",
        width: "100%"
    }
}

// Theme #362222 #171010 #423F3E #2B2B2B
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    }
});

export default App;
