import React, {useEffect} from "react";
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from "./toolbar";
import Backend from "../../common/backend";
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Options from "./options";
import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import Question from "../../components/question";
import TabPanel from "../../components/tab-panel";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../authConfig";

const BULK_SELECT_ALL = 0;
const BULK_SELECT_NONE = 1;
const BULK_SELECT_INDETERMINATE = 2;

export default function Questions(props) {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [questions, setQuestions] = useStateWithCallbackLazy([]);
    const [searchResults, setSearchResults] = useStateWithCallbackLazy(null);
    const [bulkSelectState, setBulkSelectState] = React.useState(BULK_SELECT_NONE);
    const [pageSize] = React.useState(100)
    const [currentPage, setCurrentPage] = React.useState(1)
    const {instance, accounts} = useMsal();

    const [options, setOptions] = React.useState({
        pinNewItems: false,
        expandNewItems: false
    });

    useEffect(() => {
        instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).then((response) => {
            Backend.getQuestions(response.accessToken).then((data) => {
                let pinnedQuestions = [];
                let unPinnedQuestions = [];

                data.forEach((item) => {
                    const d = {
                        data: item,
                        ui: {checked: false, expanded: false}
                    }

                    if (d.data.pinned === true) {
                        pinnedQuestions.push(d);
                    } else {
                        unPinnedQuestions.push(d);
                    }
                })
                setQuestions([...pinnedQuestions, ...unPinnedQuestions], () => {
                });
            })
        }).catch(() => {
            const currentAccount = instance.getActiveAccount();
            instance.logoutRedirect({
                account: currentAccount
            }).then(()=>{});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, instance])

    const tabChanged = (event, newTab) => {
        setCurrentTab(newTab);
    };

    const bulkSelectClicked = () => {
        let newQuestions = [...questions];
        newQuestions.forEach(nq => {
            nq.ui.checked = bulkSelectState === BULK_SELECT_NONE || bulkSelectState === BULK_SELECT_INDETERMINATE;
        })
        updateQuestions(newQuestions)
    }

    const questionSelected = (id) => {
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(element => element.data.id === id);
        newQuestions[index].ui.checked = newQuestions[index].ui.checked === false;

        updateQuestions(newQuestions)
    };

    const questionPinned = (id) => {
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(element => element.data.id === id);
        newQuestions[index].data.pinned = !newQuestions[index].data.pinned;

        updateQuestions(newQuestions)
    };

    const pagingChanged = (event, value) => {
        setCurrentPage(value)
    }

    const addQuestionClicked = () => {
        instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).then((response) => {
            return Backend.addQuestion(response.accessToken);
        }).then(newQuestion => {
            const q = {
                data: newQuestion,
                ui: { checked: false }
            }

            if (options.pinNewItems) {
                q.data.pinned = true;
            }
            if (options.expandNewItems) {
                q.ui.expanded = true;
            }

            setQuestions([q, ...questions], ()=>{})
        });
    }

    const removeQuestionClicked = () => {
        let newQuestions = [...questions];

        let deletedQuestions = [];
        let filteredQuestions = [];
        for (let q of newQuestions) {
            if (!q.ui.checked) {
                filteredQuestions.push(q);
            } else {
                deletedQuestions.push(q);
            }
        }

        instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).then((response) => {
            const deletePromises = deletedQuestions.map(dq => {
                return Backend.removeQuestion(response.accessToken, dq.data.id);
            })
            return Promise.all(deletePromises);
        }).then(() => {
            updateQuestions(filteredQuestions)
        })
    }

    const bulkPinClicked = (pin) => {
        let pinnedQuestions = [];
        let unPinnedQuestions = [];

        let newQuestions = [...questions];

        for (let q of newQuestions) {
            if (q.ui.checked) {
                q.data.pinned = pin
            }

            if (q.data.pinned === true) {
                pinnedQuestions.push(q);
            } else {
                unPinnedQuestions.push(q);
            }
        }

        setQuestions([...pinnedQuestions, ...unPinnedQuestions],()=>{})
    }

    const searchClicked = (text) => {
        if (!text) {
            setSearchResults(null, () => {
                setCurrentPage(1)
            });
            return
        }

        let newQuestions = [...questions];
        let filteredQuestions = [];
        for (let q of newQuestions) {
            if (q.data.question.toUpperCase().indexOf(text.toUpperCase()) !== -1) {
                filteredQuestions.push(q)
            }
        }

        // noinspection JSCheckFunctionSignatures
        setSearchResults(filteredQuestions, ()=>{
            setCurrentPage(1)
        })
    }

    const updateQuestions = (questionArr) => {
        let pinnedQuestions = [];
        let unPinnedQuestions = [];

        let allChecked= true;
        let noneChecked = true;
        for (let q of questionArr) {
            if (q.ui.checked === true) {
                noneChecked = false // If one of the questions is checked, all questions cannot be not checked
            } else {
                allChecked = false // If one of the questions is not checked, all questions cannot be checked
            }

            if (q.data.pinned === true) {
                pinnedQuestions.push(q);
            } else {
                unPinnedQuestions.push(q);
            }
        }

        setQuestions([...pinnedQuestions, ...unPinnedQuestions], ()=> {
            if (questionArr.length === 0 || noneChecked) {
                setBulkSelectState(BULK_SELECT_NONE);
            } else if (allChecked) {
                setBulkSelectState(BULK_SELECT_ALL);
            } else {
                setBulkSelectState(BULK_SELECT_INDETERMINATE);
            }
        });
    }

    const optionChanged = (name, value) => {
        let newOptions = {...options}
        newOptions[name] = value
        setOptions(newOptions)
    }

    const start = (currentPage - 1) * pageSize;
    const end = (currentPage - 1) * pageSize + pageSize;
    const visibleQuestions = (searchResults ? searchResults : questions).slice(start, start + end > questions.length ? questions.length : start + end);

    return (
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={tabChanged} aria-label="basic tabs example">
                    <Tab label="My Questions" id={"questions-tab-0"} aria-controls={"questions-tabpanel-0"} />
                    <Tab label="Shared with me" id={"questions-tab-1"} aria-controls={"questions-tabpanel-1"}  />
                </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0} tabPanelName={'questions-tab-panel'} tabName={'questions-tab'} sx={{p:0}}>
                <Toolbar showAdd={true} addButtonHandler={addQuestionClicked} searchClicked={searchClicked}/>
                {
                    !props.isMobile &&
                    <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", marginBottom: "10px"}}>
                        <Box style={{display: "flex", alignItems: "center", justifyContent: "left", marginRight: "5px"}}>
                            <Checkbox checked={bulkSelectState === BULK_SELECT_ALL}
                                      indeterminate={bulkSelectState === BULK_SELECT_INDETERMINATE}
                                      style={{padding: 0, marginRight: "10px"}}
                                      disabled={questions.length === 0}
                                      onChange={bulkSelectClicked}
                                      title={"Select/unselect all"}/>
                            {
                                bulkSelectState !== BULK_SELECT_NONE &&
                                <Box>
                                    <IconButton aria-label="delete" onClick={removeQuestionClicked} title={"Remove"} >
                                        <DeleteIcon />
                                    </IconButton>

                                    <IconButton aria-label="pin" onClick={() => bulkPinClicked(true)} title={"Pin"}>
                                        <PushPinIcon style={{color: "white", transform: "rotate(45deg)", position: "relative", top: "2px"}} />
                                    </IconButton>

                                    <IconButton aria-label="pin" onClick={() => bulkPinClicked(false)} title={"Unpin"}>
                                        <PushPinOutlinedIcon style={{transform: "rotate(45deg)", position: "relative", top: "2px"}} />
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                        <Box style={{display: "flex", alignItems: "center", justifyContent: "right"}}>
                            <Options options={options} optionChanged={optionChanged} />
                        </Box>
                    </Box>
                }
                <Divider style={{marginBottom: "10px"}} />
                {
                    visibleQuestions.length > 0 && visibleQuestions.map((uq, index) => (
                        <React.Fragment key={uq.data.id}>
                            <table cellPadding={0} cellSpacing={0} style={{width: "100%"}} >
                                <tbody>
                                    <tr>
                                        {
                                            !props.isMobile &&
                                            <td style={{width: "34px"}}>
                                                <Checkbox
                                                    style={{position: "relative", top: "-6px", padding: 0}}
                                                    checked={visibleQuestions[index].ui.checked === true}
                                                    onChange={() => questionSelected(visibleQuestions[index].data.id)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </td>
                                        }
                                        {
                                            !props.isMobile &&
                                            <td style={{width: "34px"}}>
                                                <Checkbox
                                                    style={{position: "relative", top: "-6px", padding: 0}}
                                                    checked={visibleQuestions[index].data.pinned === true}
                                                    onChange={() => questionPinned(visibleQuestions[index].data.id)}
                                                    icon={<PushPinOutlinedIcon style={{transform: "rotate(45deg)"}} />}
                                                    checkedIcon={<PushPinIcon style={{color: "white", transform: "rotate(45deg)"}} />} />
                                            </td>
                                        }
                                        <td>
                                            <Question question={uq.data} expanded={uq.ui.expanded} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </React.Fragment>
                    ))
                }
                {
                    ((searchResults ? searchResults : questions).length / pageSize) > 1 &&
                    <Box style={{display: "flex", alignItems: "center", justifyContent: "right"}}>
                        <Pagination
                            count={Math.ceil((searchResults ? searchResults : questions).length / pageSize) }
                            page={currentPage}
                            onChange={pagingChanged}
                            siblingCount={0}
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...item}
                                />
                            )}
                        />
                    </Box>
                }
            </TabPanel>
            <TabPanel value={currentTab} index={1} tabPanelName={'questions-tab-panel'} tabName={'questions-tab'}>
                <Toolbar showAdd={false} />
                {
                    visibleQuestions.length > 0 && visibleQuestions.map((uq, index) => (
                        <React.Fragment key={index}>
                            <table cellPadding={0} cellSpacing={0} style={{width: "100%"}} >
                                <tbody>
                                <tr>
                                    {
                                        !props.isMobile &&
                                        <td style={{width: "34px"}}>
                                            <Checkbox
                                                style={{position: "relative", top: "-6px", padding: 0}}
                                                checked={visibleQuestions[index].ui.checked === true}
                                                onChange={() => questionSelected(visibleQuestions[index].data.id)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </td>
                                    }
                                    <td>
                                        <Question question={uq.data} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </React.Fragment>
                    ))
                }
            </TabPanel>
        </React.Fragment>
    )
}
