import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import QuizGroup from "./quiz-group";
import Add from "@mui/icons-material/Add";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default function Quiz(props) {
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        let splits = result.draggableId.split("_")
        const draggableType = splits[0]

        if (draggableType === "GROUP") {
            const sortedGroups = reorder(
                props.quiz.groups,
                result.source.index,
                result.destination.index
            );

            props.updateGroupsHandler(props.quiz.id, [...sortedGroups])
        } else {
            /*
                We are dragging questions, which means we have to know 1) which question has been dragged and
                2) in which group. The questions dragging id takes form QUESTION_<group id>_index, which we use
                to determine the right group. Since we are sorting questions, we must update the correct group based on
                the group's index in the groups array. To figure out the index, we clone the groups array, loop it through
                and figure out which index of the array contained the group with the id we are looking for. When we
                know that, we update the order of questions in that group and store the updated groups array to state.
             */
            let draggedGroupId = parseInt(splits[1], 10)
            let newGroups = JSON.parse(JSON.stringify(props.quiz.groups))

            let matchingGroupIndex = null
            for (let i = 0; i < newGroups.length; i++) {
                if (newGroups[i].id === draggedGroupId) {
                    matchingGroupIndex = i
                    break;
                }
            }

            newGroups[matchingGroupIndex].questions = reorder(
                newGroups[matchingGroupIndex].questions,
                result.source.index,
                result.destination.index
            );

            props.updateGroupsHandler(props.quiz.id, [...newGroups])
        }
    }

    // noinspection DuplicatedCode
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [groupName, setGroupName] = React.useState(null)
    const addButtonRef = React.useRef(null);

    // noinspection DuplicatedCode
    function openDialog() {
        setDialogOpen(true)
    }

    function cancelDialog() {
        setDialogOpen(false)
    }

    function closeDialogAndAddGroup() {
        if (groupName && groupName.length > 0 ) {
            setDialogOpen(false)
            props.addGroupHandler(props.quiz.id, groupName)
            setTimeout(() => {
                addButtonRef.current.blur()
            }, 100);
        }
    }

    function addQuestion(groupId) {
        props.addQuestionHandler(props.quiz.id, groupId)
    }

    function removeGroup(groupId) {
        props.removeGroupHandler(props.quiz.id, groupId)
    }

    function updateQuestion(groupId, questionId, questionData) {
        props.updateQuestionHandler(props.quiz.id, groupId, questionId, questionData)
    }

    function updateGroupName(evt) {
        setGroupName(evt.target.value)
    }

    return (
        <Box sx={{width: "100%"}}>
            {props.quiz !== null && props.quiz !== undefined &&
                <Box>
                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h2>{props.quiz.name}</h2>
                        <Box style={{display: 'flex', justifyContent: 'right'}}>
                            <IconButton aria-label="add" title={"Add new group"} style={{height: "40px", marginTop: "15px"}} onClick={openDialog} ref={addButtonRef}>
                                <Add />
                            </IconButton>
                            <IconButton aria-label="menu" title={"Remove quiz"} style={{height: "40px", marginTop: "15px"}} onClick={props.removeQuizHandler}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <DragDropContext onDragEnd={onDragEnd} >
                        <Droppable droppableId="GROUP" type="GROUP">
                            {provided => (
                                <div ref={provided.innerRef}>
                                    {
                                        props.quiz.groups !== null &&
                                        props.quiz.groups.map((group, index) => (
                                            <Draggable key={"GROUP_"+group.id} draggableId={"GROUP_"+group.id} index={index} style={{marginBottom: "10px"}}>
                                                {(provided) => (
                                                    <Item provided={provided} innerRef={provided.innerRef} group={group}
                                                          addQuestionHandler={addQuestion}
                                                          updateQuestionHandler={updateQuestion}
                                                          removeGroupHandler={() => {removeGroup(group.id)}}
                                                    />
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            }
            <Dialog open={dialogOpen} onClose={cancelDialog}>
                <DialogTitle>New Group</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Quiz name"
                        type="quizname"
                        fullWidth
                        variant="standard"
                        onChange={evt => updateGroupName(evt)}
                        onKeyUp={(e) => {
                            const ENTER = 13;

                            let key = e.key || e.keyCode
                            if (key === "Enter" || key === ENTER) {
                                closeDialogAndAddGroup()
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDialog}>Cancel</Button>
                    <Button onClick={closeDialogAndAddGroup}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


class Item extends React.Component {
    render() {
        const { provided, innerRef, group } = this.props;
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={innerRef}
            >
                {
                    <QuizGroup key={group.name} name={group.name} id={group.id} questions={group.questions}
                               addQuestionHandler={this.props.addQuestionHandler}
                               updateQuestionHandler={this.props.updateQuestionHandler}
                               removeGroupHandler={this.props.removeGroupHandler}
                    />
                }
            </div>
        );
    }
}