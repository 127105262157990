import {Menu, MenuItem, ProSidebar, SubMenu} from "react-pro-sidebar";
import {IoGameControllerOutline} from "react-icons/io5";
import {BsBorderAll, BsListOl} from "react-icons/bs";
import {Link} from "react-router-dom";
import {FaRegLightbulb} from "react-icons/fa";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";

export default function Sidebar(props) {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [quizName, setQuizName] = React.useState(null)
    const addButtonRef = React.useRef(null);

    function openDialog() {
        setDialogOpen(true)
    }

    function cancelDialog() {
        setDialogOpen(false)
    }

    function closeDialogAndAddQuiz() {
        if(quizName && quizName.length > 0 ) {
            setDialogOpen(false)
            props.addQuizHandler(quizName)
            setTimeout(() => {
                addButtonRef.current.blur()
            }, 100);
        }
    }

    function updateQuizName(evt) {
        setQuizName(evt.target.value)
    }

    return (
        <div>
            <ProSidebar>
                <Menu iconShape="square">
                    <MenuItem icon={<FaRegLightbulb />}>
                        Questions
                        <Link to="/questions" />
                    </MenuItem>
                    <SubMenu title="Games" icon={<IoGameControllerOutline />}>
                        <MenuItem icon={<BsBorderAll />}>
                            Select Correct
                            <Link to="/games/select-correct" />
                        </MenuItem>
                        <MenuItem icon={<BsListOl />}>
                            Order List
                            <Link to="/games/order-list" />
                        </MenuItem>
                    </SubMenu>
                    <SubMenu title="Quizzes" defaultOpen={props.quizzesOpen} icon={<MenuBookIcon />}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button variant={"outlined"} style={{...styles.addButton}} startIcon={<Add />} title={"Add new quiz"} ref={addButtonRef}
                                    onClick={openDialog} >
                                New
                            </Button>
                        </div>
                        {props.quizzes.map((quiz) => (
                            <MenuItem key={quiz.id}>
                                {quiz.name}
                                <Link to={"/quizzes/" + quiz.id} />
                            </MenuItem>
                        ))}
                    </SubMenu>
                </Menu>
            </ProSidebar>
            <Dialog open={dialogOpen} onClose={cancelDialog}>
                <DialogTitle>New Quiz</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Quiz name"
                        type="quizname"
                        fullWidth
                        variant="standard"
                        onChange={evt => updateQuizName(evt)}
                        onKeyUp={(e) => {
                            const ENTER = 13;

                            let key = e.key || e.keyCode
                            if (key === "Enter" || key === ENTER) {
                                closeDialogAndAddQuiz()
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDialog}>Cancel</Button>
                    <Button onClick={closeDialogAndAddQuiz}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

const styles = {
    addButton: {
        height: 30,
        borderColor: "white",
        color: "white",
        marginBottom: 10
    }
}