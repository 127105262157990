import React from "react";
import {Box, TextField} from "@mui/material";

export default function OrderListQuestion(props) {
    const answers = [...props.answers]
    let timeoutId = null

    function updateTextField(evt, answerIndex) {
        switch (answerIndex) {
            case 0: answers[0].text = evt.target.value; break
            case 1: answers[1].text = evt.target.value; break
            case 2: answers[2].text = evt.target.value; break
            case 3: answers[3].text = evt.target.value; break
            case 4: answers[4].text = evt.target.value; break
            default: break
        }

        if (timeoutId !== null ) {
            clearTimeout(timeoutId)
        }

        // It is important that we send out all answers at once so we get the fully modified state
        // Above clearTimeout might clear a pending update to answers[0] while we are here updating answers[1]
        // If we would send only the answers[1], the previous updates would be lost
        timeoutId = setTimeout( () => {
            props.answersUpdated([...answers])
        }, 1000)
    }

    return (
        <Box>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Answer #1" variant="outlined" defaultValue={answers[0] && answers[0].text} onChange={evt => updateTextField(evt, 0)}/>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Answer #2" variant="outlined" defaultValue={answers[1] && answers[1].text} onChange={evt => updateTextField(evt, 0)}/>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Answer #3" variant="outlined" defaultValue={answers[2] && answers[2].text} onChange={evt => updateTextField(evt, 0)}/>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Answer #4" variant="outlined" defaultValue={answers[3] && answers[3].text} onChange={evt => updateTextField(evt, 0)}/>
            <TextField sx={{width: "100%", marginBottom: "15px"}} id="outlined-basic"
                       label="Answer #5" variant="outlined" defaultValue={answers[4] && answers[4].text} onChange={evt => updateTextField(evt, 0)}/>
        </Box>
    );
}
