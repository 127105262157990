import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_my_lerneca_signin-01",
        forgotPassword: "b2c_1_my_lerneca_password_reset-01",
        editProfile: "b2c_1_my_lerneca_edit_profile-01"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://mylerneca.b2clogin.com/mylerneca.onmicrosoft.com/b2c_1_my_lerneca_signin-01",
        },
        forgotPassword: {
            authority: "https://mylerneca.b2clogin.com/mylerneca.onmicrosoft.com/b2c_1_my_lerneca_password_reset-01",
        },
        editProfile: {
            authority: "https://mylerneca.b2clogin.com/mylerneca.onmicrosoft.com/b2c_1_my_lerneca_edit_profile-01"
        }
    },
    authorityDomain: "mylerneca.b2clogin.com"
}

export const msalConfig = {
    auth: {
        clientId: "4dd0e1bb-fcd5-4fb7-b837-f084fc6976a1",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage", // "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        console.log(message);
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: [
        "https://mylerneca.onmicrosoft.com/4dd0e1bb-fcd5-4fb7-b837-f084fc6976a1/Questions.Read",
        "https://mylerneca.onmicrosoft.com/4dd0e1bb-fcd5-4fb7-b837-f084fc6976a1/Questions.Write"
    ]
};
