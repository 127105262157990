class Utils {
    findById(arr, id) {
        for (let item of arr) {
            if (item.id === id) {
                return item
            }
        }
        return null
    }

    excludeById(arr, id) {
        let filtered = []
        for (let item of arr) {
            if (item.id !== id) {
                filtered.push(item)
            }
        }
        return filtered
    }
}

export default new Utils()
