import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectCorrectQuestion from "../../components/select-correct-question";
import OrderListQuestion from "../../components/order-list-question";
import Add from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import {Draggable, Droppable} from "react-beautiful-dnd";

export default function QuizGroup(props) {

    function updateQuestion(questionId, updatedData) {
        props.updateQuestionHandler(props.id, questionId, updatedData)
    }
    return (
        <div>
            {props.name !== null &&
            <Accordion sx={{backgroundColor: "#423F3E"}} className={"quiz-group"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box style={{fontSize: "18px", fontWeight: "bold"}}>{props.name}</Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box style={{display: 'flex', justifyContent: 'right', marginBottom: "10px"}}>
                        <IconButton aria-label="add" title={"Add new question"} onClick={() => props.addQuestionHandler(props.id)}>
                            <Add />
                        </IconButton>
                        <IconButton aria-label="menu" title={"Remove group"} onClick={() => props.removeGroupHandler(props.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                        <Droppable droppableId={"QUESTION_" + props.id} type={`QUESTION_` + props.id}>
                        {provided => (
                            <div ref={provided.innerRef}>
                                {
                                    props.questions !== null &&
                                    props.questions.map((question, index) => (
                                        <Draggable key={"QUESTION_"+ props.id + "_" + index} draggableId={"QUESTION_"+ props.id + "_"  + index} index={index} style={{marginBottom: "10px"}}>
                                            {(provided) => (
                                                <Item provided={provided} innerRef={provided.innerRef} question={question} updateQuestionHandler={updateQuestion} />
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                            )
                        }
                        </Droppable>
                </AccordionDetails>
            </Accordion>
            }
        </div>
    );
}

class Item extends React.Component {
    render() {
        const { provided, innerRef, question} = this.props;
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={innerRef}
            >
                {
                    question.type === "select-correct" ?
                        <SelectCorrectQuestion provided={provided} innerRef={provided.innerRef} key={question.id} question={question} updateQuestionHandler={this.props.updateQuestionHandler} />:
                        <OrderListQuestion provided={provided} innerRef={provided.innerRef} key={question.id} question={question} />
                }
            </div>
        );
    }
}