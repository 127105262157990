import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalConfig } from "./authConfig";


const msalInstance = new PublicClientApplication(msalConfig);

function ErrorComponent({error}) {
    return <p style={{color: "white"}}>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
    return <p style={{color: "white"}}>Authentication in progress...</p>;
}

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              errorComponent={ErrorComponent}
              loadingComponent={LoadingComponent}
          >
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </MsalAuthenticationTemplate>
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
