import React, {useState} from "react";
import {Button, Container, Grid, Paper} from "@mui/material";
import ResponsiveAppBar from "../../AppBar";
import Typography from "@mui/material/Typography";

const classes = {
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        textAlign: 'center',
        color: "white",
        '&:hover': {
            background: "#111",
        },
        height: "2.5em",
        cursor: "default"
    },
    container: {
        backgroundColor: "#1b1e20"
    }
}

export default function SelectCorrect() {
    const quizList = require('../../quiz/QuizList.json');
    const currentQuizName = Object.keys(quizList)[0];
    const currentQuiz = require("../../"+quizList[currentQuizName]);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showAnswerColors, setShowAnswerColors] = useState(false);
    const [currentAnswerIndex, setCurrentAnswerIndex] = useState(false);
    const [originalAnswers, setOriginalAnswers] = useState(JSON.parse(JSON.stringify(currentQuiz.questions[currentQuestionIndex].answers)));
    const [shuffledAnswers, setShuffledAnswers] = useState(JSON.parse(JSON.stringify(currentQuiz.questions[0].answers)).sort(() => Math.random() - 0.5));

    const currentQuestion = currentQuiz.questions[0];
    const nextButtonText = currentQuestionIndex === currentQuiz.questions.length - 1 ? "Finish" : "Next";

    function OnClickHandler() {
        console.log("foo");
    }

    function Answer(index) {
        if (showAnswerColors) {
            return;
        }
        setCurrentAnswerIndex(index);
        setShowAnswerColors(true);
    }

    function GoToFirstQuestion() {
        setCurrentAnswerIndex(false);
        setShowAnswerColors(false);
        setCurrentQuestionIndex(0);
        setOriginalAnswers(JSON.parse(JSON.stringify(currentQuiz.questions[0].answers)))
        setShuffledAnswers(JSON.parse(JSON.stringify(currentQuiz.questions[0].answers)).sort(() => Math.random() - 0.5))
    }

    function GoToNextQuestion() {
        setShowAnswerColors(false)
        if (currentQuestionIndex < currentQuiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setOriginalAnswers(JSON.parse(JSON.stringify(currentQuiz.questions[currentQuestionIndex + 1].answers)))
            setShuffledAnswers(JSON.parse(JSON.stringify(currentQuiz.questions[currentQuestionIndex + 1].answers)).sort(() => Math.random() - 0.5))
        }
    }

    if (showAnswerColors && currentQuestion.type === "select-correct") {
        shuffledAnswers.forEach((a) => {
            if (a.correct === true) {
                a.cssStyle = {
                    backgroundColor: "#050"
                }
            } else if (a.index === currentAnswerIndex){
                a.cssStyle = {
                    backgroundColor: "#a00"
                }
            } else {
                a.cssStyle = {
                    backgroundColor: "#242526"
                }
            }
        })
    } else{
        shuffledAnswers.forEach((a) => {
            a.cssStyle = {
                backgroundColor: "#242526"
            }
        })
    }

    return (
        <Container maxWidth="sm" style={classes.container}>
            <ResponsiveAppBar />
            <Typography variant="h6" style={{color: "white", marginBottom: 20}}>
                {currentQuiz.questions[currentQuestionIndex].question}
            </Typography>
            { currentQuestion.type !== "select-correct" &&
                <Grid container style={{marginBottom: 20}}>
                    <Grid container item xs={12} >
                        {[0, 1, 2, 3, 4].map((item) => (
                            <Grid key={item} item xs={12} style={{marginBottom: 10}}>
                                <Paper onClick={OnClickHandler} style={classes.paper}>item</Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            }
            {currentQuestion.type === "select-correct" &&
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <Paper style={{...classes.paper, ...shuffledAnswers[0].cssStyle}}
                                   onClick={() => Answer(shuffledAnswers[0].index)}>{shuffledAnswers[0].text}</Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={{...classes.paper, ...shuffledAnswers[1].cssStyle}}
                                   onClick={() => Answer(shuffledAnswers[1].index)}>{shuffledAnswers[1].text}</Paper>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <Paper style={{...classes.paper, ...shuffledAnswers[2].cssStyle}}
                                   onClick={() => Answer(shuffledAnswers[2].index)}>{shuffledAnswers[2].text}</Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={{...classes.paper, ...shuffledAnswers[3].cssStyle}}
                                   onClick={() => Answer(shuffledAnswers[3].index)}>{shuffledAnswers[3].text}</Paper>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                        <Grid container item xs={6} spacing={1}>
                            {originalAnswers[currentAnswerIndex] && originalAnswers[currentAnswerIndex].correct && showAnswerColors &&
                                <div style={{marginLeft: 5, marginTop: 10, color: "white"}}>+500 points</div>
                            }
                        </Grid>

                        <Grid container item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {(currentQuestionIndex > 0 || showAnswerColors) &&
                                <Button variant={"contained"}
                                    style={{
                                        backgroundColor: "#242526",
                                        color: "white",
                                        marginRight: 5,
                                        marginTop: 5
                                    }}
                                    onClick={() => GoToFirstQuestion()}
                                >Start over</Button>
                            }
                            <Button variant={"contained"}
                                    style={{
                                        backgroundColor: "#242526",
                                        color: !showAnswerColors ? "grey" : "white",
                                        marginRight: 0,
                                        marginTop: 5
                                    }}
                                    disabled={!showAnswerColors}
                                    onClick={() => GoToNextQuestion()}
                            >{nextButtonText}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Container>
    );
}