import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, FormControl, InputLabel, Select} from "@mui/material";
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./tab-panel";
import SelectCorrectQuestion from "./select-correct-question";
import SingleAnswerQuestion from "./single-answer-question";
import OrderListQuestion from "./order-list-question";
import ConfirmationDialog from "./confirmation-dialog";
import MenuItem from "@mui/material/MenuItem";
import {loginRequest} from "../authConfig";
import Backend from "../common/backend";
import {useMsal} from "@azure/msal-react";
import UpdatingTextfield from "./updating-textfield";

export default function Question(props) {
    const [questionType, setQuestionType] = useStateWithCallbackLazy(props.question.type);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [title, setTitle] = React.useState(props.question.question);
    const [references, setReferences] = React.useState(props.question.references);
    const [facts, setFacts] = React.useState(props.question.facts.text);
    const [factsSource, setFactsSource] = React.useState(props.question.facts.source);
    const [newQuestionTypeCandidate, setNewQuestionTypeCandidate] = useStateWithCallbackLazy(false);
    const [answers, setAnswers] = useStateWithCallbackLazy([...props.question.answers]);
    const [answersExpanded, setAnswersExpanded] = React.useState(props.expanded);
    const [currentTab, setCurrentTab] = React.useState(0);
    const {instance, accounts} = useMsal();


    function accordionExpanded() {
        //updateQuestion()
        setAnswersExpanded(!answersExpanded)
    }

    const tabChanged = (event, newTab) => {
        setCurrentTab(newTab);
    };

    const questionTypeChangeTriggered = (event) => {
        setNewQuestionTypeCandidate(event.target.value, () => {
            setConfirmationDialogOpen(true);
        })
    }

    const confirmationDialogAgreed = async () => {
        switch (newQuestionTypeCandidate) {
            case "select-correct": {
                const response = await updateQuestion({type: "select-correct"});
                return setAnswers(response.answers, () => {
                    setQuestionType(newQuestionTypeCandidate, ()=>{
                        setNewQuestionTypeCandidate(null, () => {
                            setConfirmationDialogOpen(false);
                        })
                    })});
            }
            case "single-answer": {
                const response = await updateQuestion({type: "single-answer"});
                return setAnswers(response.answers, () => {setQuestionType(newQuestionTypeCandidate, ()=>{
                    setNewQuestionTypeCandidate(null, () => {
                        setConfirmationDialogOpen(false);
                    })
                })});
            }
            case "order-list": {
                const response = await updateQuestion({type: "order-list"});
                return setAnswers(response.answers, () => {setQuestionType(newQuestionTypeCandidate, ()=>{
                    setNewQuestionTypeCandidate(null, () => {
                        setConfirmationDialogOpen(false);
                    })
                })});
            }
            default: return
        }
    }

    const confirmationDialogDisagreed = () => {
        setNewQuestionTypeCandidate(null, () => {
            setConfirmationDialogOpen(false);
        })
    }

    const answersUpdated = async (newAnswers) => {
        await updateQuestion({answers: newAnswers});
        setAnswers([...newAnswers], () => {});
    }

    const updateQuestion = async (data) => {
        const response = await instance.acquireTokenSilent({...loginRequest, account: accounts[0]});
        return Backend.updateQuestion(response.accessToken, props.question.id, data);
    }

    const titleUpdated = async (newTitle) => {
        await updateQuestion({question: newTitle});
        setTitle(newTitle);
    }

    const referencesUpdated = async (newReferences) => {
        await updateQuestion({references: newReferences});
        setReferences([...newReferences]);
    }

    const factsUpdated = async (newFacts) => {
        await updateQuestion({"facts.text": newFacts});
        setFacts(newFacts);
    }

    const factsSourceUpdated = async (newFactsSource) => {
        await updateQuestion({"facts.source": newFactsSource});
        setFactsSource(newFactsSource);
    }

    return (
        <Box>
            <Accordion expanded={answersExpanded}
                       key={props.question.id}
                       sx={{backgroundColor: "#2B2B2B", '& .MuiAccordionDetails-root': {padding: "0 5px "}}}
                       onChange={accordionExpanded}
                       className={"quiz-question"}
                       TransitionProps={{ timeout: 0}} >

                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{props.question.question}</Typography>
                </AccordionSummary>

                <AccordionDetails style={{padding: "24px, 0, 0, 0"}}>
                    <Tabs value={currentTab} onChange={tabChanged} aria-label="basic tabs example" sx={{'& .MuiBox-root': {padding: 0}}} >
                        <Tab label="Question" id={"questions-tab-0"} aria-controls={"question-tabpanel-0"} style={{paddingLeft: "5px", paddingRight: "5px"}} />
                        <Tab label="Answers" id={"questions-tab-1"} aria-controls={"question-tabpanel-1"} style={{paddingLeft: "5px", paddingRight: "5px"}} />
                        <Tab label="Facts" id={"questions-tab-2"} aria-controls={"question-tabpanel-2"} style={{paddingLeft: "5px", paddingRight: "5px"}} />
                    </Tabs>

                    <TabPanel value={currentTab} index={0} tabPanelName={'question-tab-panel'} tabName={'question-tab'}>
                        <UpdatingTextfield style={textFieldStyle} value={title} label={"Title"} valueUpdated={titleUpdated} />
                    </TabPanel>

                    <TabPanel value={currentTab} index={1} tabPanelName={'question-tab-panel'} tabName={'question-tab'}>
                        <FormControl fullWidth style={{marginBottom: "25px"}}>
                            <InputLabel id="question-select-label">Question type</InputLabel>
                            <Select
                                labelId="question-select-label"
                                id="question-select"
                                value={questionType}
                                label="Question type"
                                onChange={(event) => {questionTypeChangeTriggered(event)}}
                            >
                                <MenuItem value={"select-correct"}>Select Correct</MenuItem>
                                <MenuItem value={"order-list"}>Order List</MenuItem>
                                <MenuItem value={"single-answer"}>Single Answer</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            renderAnswers(answers, questionType, answersUpdated)
                        }
                        <UpdatingTextfield style={textFieldStyle} value={references} multilineAsArray label={"References"} valueUpdated={referencesUpdated} />
                    </TabPanel>

                    <TabPanel value={currentTab} index={2} tabPanelName={'question-tab-panel'} tabName={'question-tab'} sx={{p:0}}>
                        <UpdatingTextfield style={textFieldStyle} value={facts} label={"Facts"} valueUpdated={factsUpdated} />
                        <UpdatingTextfield style={textFieldStyle} value={factsSource} label={"Facts Source"}  valueUpdated={factsSourceUpdated} />
                    </TabPanel>
                </AccordionDetails>
            </Accordion>
            <ConfirmationDialog
                open={confirmationDialogOpen} title={"Reset answers?"}
                contents={"Changing question type resets answers. This cannot be undone. Continue?"}
                cancelClicked={()=>{confirmationDialogDisagreed()}}
                okClicked={async ()=>{await confirmationDialogAgreed()}} />
        </Box>
    );
}

const textFieldStyle = {
    width: "100%",
    marginBottom: "20px"
}

function renderAnswers(answers, questionType, answersUpdated) {
    switch (questionType) {
        case "select-correct": {
            return (<SelectCorrectQuestion answers={answers} answersUpdated={answersUpdated} />)
        }
        case "single-answer": {
            return (<SingleAnswerQuestion answers={answers} answersUpdated={answersUpdated} />)
        }
        case "order-list": {
            return (<OrderListQuestion answers={answers} answersUpdated={answersUpdated} />)
        }
        default: return (<div />)
    }
}
