import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link} from "react-router-dom";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LogoutIcon from "@mui/icons-material/Logout";

export default function TemporaryDrawer(props) {
    return (
        <Drawer
            anchor={"left"}
            open={props.sideMenuOpen}
            onClose={() => {props.setSideMenuOpen(false)}}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => {props.setSideMenuOpen(false)}}
                onKeyDown={() => {props.setSideMenuOpen(false)}}
            >
                <List style={{marginTop: "60px"}}>
                    <ListItem button key={"Questions"} component={Link} to={"/questions"} selected={true} onClick={() => {props.setSideMenuOpen(false)}}>
                        <ListItemIcon>
                            <LightbulbIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Questions"} />
                    </ListItem>
                    <ListItem button key={"Quizzes"} component={Link} to={"/quizzes"} onClick={() => {props.setSideMenuOpen(false)}}>
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Quizzes"} />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button key={"Logout"} onClick={() => {props.setSideMenuOpen(false)}}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}
